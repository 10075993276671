var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loginPageCtrl === 'show')?_c('v-app',{staticStyle:{"width":"100%","height":"100%"}},[_c('div',{style:({
      minHeight: '700px',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      padding: 0,
      margin: 0,
      backgroundImage: ("url(" + _vm.baseUrl + "/upload/" + _vm.loginUrl + ")"),
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed'
    })},[_c('Logo',{attrs:{"logoDesc":_vm.logoDesc,"fontColor":_vm.fontColor,"configId":_vm.configId}}),_c('div',{class:[
        _vm.loginSite === 'left'
          ? 'leftStyle d-flex'
          : _vm.loginSite === 'right'
          ? 'rightStyle d-flex'
          : 'centerStyle d-flex'
      ]},[(
          (_vm.loginSite === 'right' || _vm.loginSite === 'center') &&
            _vm.noticeDisplay !== 'staticState'
        )?_c('Notice',{attrs:{"sonData":_vm.sonData,"noticeShow":_vm.noticeShow},on:{"noticeChange":function($event){return _vm.noticeChange($event)}}}):_vm._e(),(
          _vm.loginSite === 'right' &&
            _vm.showUseInstruction == true &&
            _vm.noticeDisplay === 'staticState'
        )?_c('StaticNotice',{attrs:{"sonData":_vm.sonData}}):_vm._e(),_c('div',{staticClass:"white--text",style:({
          position:
            (_vm.loginSite === 'right' &&
              (_vm.showUseInstruction == true || _vm.showUseInstruction == false) &&
              _vm.noticeDisplay === 'dynamic') ||
            (_vm.loginSite === 'right' &&
              _vm.showUseInstruction == false &&
              _vm.noticeDisplay === 'staticState')
              ? 'absolute'
              : 'relative',
          right:
            (_vm.loginSite === 'right' &&
              (_vm.showUseInstruction == true || _vm.showUseInstruction == false) &&
              _vm.noticeDisplay === 'dynamic') ||
            (_vm.loginSite === 'right' &&
              _vm.showUseInstruction == false &&
              _vm.noticeDisplay === 'staticState')
              ? '14%'
              : '0',
          width: '380px',
          minHeight: '480px',
          borderRadius: '6px',
          background: ("rgba(" + _vm.loginBgColor + "," + _vm.loginBgTransparency + ")")
        })},[(_vm.showCode)?_c('img',{staticStyle:{"height":"52px","position":"absolute","right":"4px","top":"4px","color":"#fff","cursor":"pointer"},attrs:{"src":_vm.qrcode
              ? ("data:image/png;base64," + (_vm.consts.qrcodeUrl))
              : ("data:image/png;base64," + (_vm.consts.passwordUrl))},on:{"click":_vm.coverType}}):_vm._e(),(_vm.defaultCode)?_c('img',{staticStyle:{"height":"52px","position":"absolute","right":"4px","top":"4px","color":"#fff","cursor":"pointer"},attrs:{"src":_vm.defaultCodeImg
              ? ("data:image/png;base64," + (_vm.consts.passwordUrl))
              : ("data:image/png;base64," + (_vm.consts.qrcodeUrl))},on:{"click":_vm.coverDefaultCodeType}}):_vm._e(),(!_vm.specifiedSource)?_c('div',{staticClass:"pa-5 pt-8",style:({ margin: _vm.qrcode ? '0 auto' : '16px auto' })},[(_vm.qrcode)?_c('div',[(_vm.tabSources.length > 0)?_c('v-tabs',{staticClass:"my-4",attrs:{"fixed-tabs":"","color":_vm.highlightColor},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.tabSources),function(source){return _c('v-tab',{key:source.name,staticClass:"tab-item",style:(_vm.styleObject),on:{"change":function($event){return _vm.tabTap(source.id)}}},[_c('span',[_vm._v(_vm._s(source.name))])])}),1):_c('v-tabs',{staticClass:"my-4",attrs:{"fixed-tabs":"","color":_vm.highlightColor}},[_c('v-tab',{staticClass:"tab-item",style:(_vm.styleObject)},[_c('span',[_vm._v("其他登录方式")])])],1)],1):_vm._e(),(_vm.qrcode)?_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.tabSources),function(source){return _c('v-tab-item',{key:source.id},[(
                  source.type === 'PASSWORD' ||
                    source.type === 'LDAP' ||
                    source.type === 'FNU_LDAP' ||
                    source.type === 'RADIUS' ||
                    source.type === 'SMS'
                )?_c('div',[_c(_vm.loginCards[source.type],{tag:"component",attrs:{"sonData":_vm.sonData,"curId":_vm.curId,"curItem":_vm.curItem,"fromType":_vm.fromType},on:{"showAccountEvent":function($event){return _vm.showAccountEvent($event)},"noticeChange":function($event){return _vm.noticeChange($event)}}})],1):_c('div',[_c('div',{staticStyle:{"position":"relative","margin-top":"40px"}},[(_vm.scanSuccess)?_c('div',{staticClass:"text-caption d-flex flex-column justify-center",staticStyle:{"width":"270px","height":"270px","overflow":"hidden","background":"#fff","opacity":"0.9","position":"absolute","top":"1px","left":"35px"}},[_c('v-icon',{attrs:{"size":"100","color":"#07c160"}},[_vm._v("mdi-check-circle")]),_c('div',{staticClass:"text-center text-body-1"},[_vm._v("扫码成功")])],1):_vm._e(),(_vm.isValid)?_c('div',{staticClass:"text-caption d-flex flex-column justify-center",staticStyle:{"width":"270px","height":"270px","overflow":"hidden","background":"#fff","opacity":"0.9","position":"absolute","top":"1px","left":"35px"}},[_c('v-icon',{attrs:{"size":"100","color":"#F5A623"}},[_vm._v("mdi-alert-circle")]),_c('div',{staticClass:"text-center text-body-1 mt-2 black--text"},[_vm._v(" 二维码已过期 "),_c('span',{staticClass:"blue--text",staticStyle:{"cursor":"pointer"},on:{"click":_vm.toRefresh}},[_vm._v("刷新")])])],1):_vm._e(),(
                      source &&
                        (source.type === 'WECHAT_RZKC' ||
                          (source.type === 'WECHAT_ENTERPRISE' &&
                            source.wechat_qrcode))
                    )?_c('div',{ref:"wechat_rzkc_code",refInFor:true,staticClass:"text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"270px","height":"270px","margin":"0 auto","overflow":"hidden","padding":"16px","background":"#fff"},attrs:{"id":"wechat_rzkc_code"}}):_vm._e()]),(source && source.type === 'DING_TALK')?_c('div',{ref:"ding_code",refInFor:true,staticClass:"px-2 py-2 text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"270px","height":"270px","margin":"0 auto","overflow":"hidden"},attrs:{"id":"ding_code"}}):_vm._e(),(source && source.type === 'WECHAT_ENTERPRISE')?_c('div',{ref:"enterprise_code",refInFor:true,staticClass:"px-2 py-2 text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"280px","height":"340px","margin":"0 auto","overflow":"hidden"},attrs:{"id":"enterprise_code"}}):_vm._e()])])}),1):_vm._e(),(_vm.qrcode)?_c('OtherLink',{attrs:{"otherLogins":_vm.otherLogins,"tabSources":_vm.tabSources}}):_c('v-card',{staticClass:"d-flex flex-column justify-center",style:({
              height:
                _vm.curCode && _vm.curCode.type === 'WECHAT_ENTERPRISE'
                  ? '394px'
                  : '350px'
            }),attrs:{"flat":""}},[_c('v-tabs',{attrs:{"fixed-tabs":"","color":_vm.highlightColor},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.codeTabSources),function(source){return _c('v-tab',{key:source.name,staticClass:"tab-item",style:(_vm.styleObject),on:{"change":function($event){return _vm.tabTap(source.id)}}},[_c('span',[_vm._v(_vm._s(source.name))])])}),1),(!_vm.showWechatAccount)?_c('div',{staticStyle:{"position":"relative","margin-top":"40px"}},[(_vm.scanSuccess)?_c('div',{staticClass:"text-caption d-flex flex-column justify-center",staticStyle:{"width":"270px","height":"270px","overflow":"hidden","background":"#fff","opacity":".9","position":"absolute","top":"1px","left":"35px"}},[_c('v-icon',{attrs:{"size":"100","color":"#07c160"}},[_vm._v("mdi-check-circle")]),_c('div',{staticClass:"text-center text-body-1"},[_vm._v("扫码成功")])],1):_vm._e(),(_vm.isValid)?_c('div',{staticClass:"text-caption d-flex flex-column justify-center",staticStyle:{"width":"270px","height":"270px","overflow":"hidden","background":"#fff","opacity":".9","position":"absolute","top":"1px","left":"35px"}},[_c('v-icon',{attrs:{"size":"100","color":"#F5A623"}},[_vm._v("mdi-alert-circle")]),_c('div',{staticClass:"text-center text-body-1 mt-2"},[_vm._v(" 二维码已过期 "),_c('span',{staticClass:"blue--text",staticStyle:{"cursor":"pointer"},on:{"click":_vm.toRefresh}},[_vm._v("刷新")])])],1):_vm._e(),(
                  _vm.curCode &&
                    (_vm.curCode.type === 'WECHAT_RZKC' ||
                      (_vm.curCode.type === 'WECHAT_ENTERPRISE' &&
                        _vm.curCode.wechat_qrcode))
                )?_c('div',{ref:"wechat_rzkc_code",staticClass:"text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"270px","height":"270px","margin":"0 auto","overflow":"hidden","background":"#fff"},attrs:{"id":"wechat_rzkc_code"}}):_vm._e()]):_vm._e(),(_vm.curCode && _vm.curCode.type === 'DING_TALK')?_c('div',{ref:"ding_code",staticClass:"px-2 py-2 text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"270px","height":"270px","margin":"0 auto","overflow":"hidden"},attrs:{"id":"ding_code"}}):_vm._e(),(_vm.curCode && _vm.curCode.type === 'WECHAT_ENTERPRISE')?_c('div',{ref:"enterprise_code",staticClass:"px-2 py-2 text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"280px","height":"400px","margin":"0 auto","overflow":"hidden"},attrs:{"id":"enterprise_code"}}):_vm._e(),(_vm.showWechatAccount)?_c('WechatBind',{attrs:{"headimgurl":_vm.headimgurl,"nickname":_vm.nickname,"sonData":_vm.sonData},on:{"doBind":_vm.doBind,"cancelBind":_vm.cancelBind}}):_vm._e()],1)],1):_c('div',{staticClass:"pa-5 pt-8",style:({ margin: _vm.qrcode ? '0 auto' : '16px auto' })},[(_vm.qrcode)?_c('v-tabs',{staticClass:"my-4",attrs:{"fixed-tabs":"","color":_vm.highlightColor},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.tabList),function(source){return _c('v-tab',{key:source.name},[_c('span',[_vm._v(_vm._s(source.name))])])}),1):_vm._e(),(_vm.qrcode)?_c('v-tabs-items',{staticStyle:{"height":"330px"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c(_vm.loginCards[_vm.specifiedSource.type],{tag:"component",attrs:{"sonData":_vm.sonData,"curId":_vm.curId,"curItem":_vm.curItem,"fromType":_vm.fromType},on:{"showAccountEvent":function($event){return _vm.showAccountEvent($event)},"noticeChange":function($event){return _vm.noticeChange($event)}}})],1)],1):_c('v-card',{staticClass:"d-flex flex-column justify-center",style:({
              height:
                _vm.curCode && _vm.curCode.type === 'WECHAT_ENTERPRISE'
                  ? '394px'
                  : '350px'
            }),attrs:{"flat":""}},[_c('v-tabs',{attrs:{"fixed-tabs":"","color":_vm.highlightColor},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.codeTabSources),function(source){return _c('v-tab',{key:source.name,staticClass:"tab-item",style:(_vm.styleObject),on:{"change":function($event){return _vm.tabTap(source.id)}}},[_c('span',[_vm._v(_vm._s(source.name))])])}),1),(!_vm.showWechatAccount)?_c('div',{staticStyle:{"position":"relative","margin-top":"40px"}},[(_vm.scanSuccess)?_c('div',{staticClass:"text-caption d-flex flex-column justify-center",staticStyle:{"width":"270px","height":"270px","overflow":"hidden","background":"#fff","opacity":".9","position":"absolute","top":"1px","left":"35px"}},[_c('v-icon',{attrs:{"size":"100","color":"#07c160"}},[_vm._v("mdi-check-circle")]),_c('div',{staticClass:"text-center text-body-1"},[_vm._v("扫码成功")])],1):_vm._e(),(_vm.isValid)?_c('div',{staticClass:"text-caption d-flex flex-column justify-center",staticStyle:{"width":"270px","height":"270px","overflow":"hidden","background":"#fff","opacity":".9","position":"absolute","top":"1px","left":"35px"}},[_c('v-icon',{attrs:{"size":"100","color":"#F5A623"}},[_vm._v("mdi-alert-circle")]),_c('div',{staticClass:"text-center text-body-1 mt-2"},[_vm._v(" 二维码已过期 "),_c('span',{staticClass:"blue--text",staticStyle:{"cursor":"pointer"},on:{"click":_vm.toRefresh}},[_vm._v("刷新")])])],1):_vm._e(),(
                  _vm.curCode &&
                    (_vm.curCode.type === 'WECHAT_RZKC' ||
                      (_vm.curCode.type === 'WECHAT_ENTERPRISE' &&
                        _vm.curCode.wechat_qrcode))
                )?_c('div',{ref:"wechat_rzkc_code",staticClass:"text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"270px","height":"270px","margin":"0 auto","overflow":"hidden","padding":"16px","background":"#fff"},attrs:{"id":"wechat_rzkc_code"}}):_vm._e()]):_vm._e(),(_vm.curCode && _vm.curCode.type === 'DING_TALK')?_c('div',{ref:"ding_code",staticClass:"px-2 py-2 text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"252px","height":"264px","margin":"0 auto","overflow":"hidden"},attrs:{"id":"ding_code"}}):_vm._e(),(_vm.curCode && _vm.curCode.type === 'WECHAT_ENTERPRISE')?_c('div',{ref:"enterprise_code",staticClass:"px-2 py-2 text-caption d-flex justify-center align-center unselect",staticStyle:{"width":"280px","height":"400px","margin":"0 auto","overflow":"hidden"},attrs:{"id":"enterprise_code"}}):_vm._e(),(_vm.showWechatAccount)?_c('WechatBind',{attrs:{"headimgurl":_vm.headimgurl,"nickname":_vm.nickname,"sonData":_vm.sonData},on:{"doBind":_vm.doBind,"cancelBind":_vm.cancelBind}}):_vm._e()],1)],1)]),(_vm.loginSite === 'left' && _vm.noticeDisplay !== 'staticState')?_c('Notice',{attrs:{"sonData":_vm.sonData,"noticeShow":_vm.noticeShow},on:{"noticeChange":function($event){return _vm.noticeChange($event)}}}):_vm._e(),(
          _vm.loginSite === 'left' &&
            _vm.showUseInstruction == true &&
            _vm.noticeDisplay === 'staticState'
        )?_c('StaticNotice',{attrs:{"sonData":_vm.sonData}}):_vm._e()],1),_c('FootDisplay',{attrs:{"sonData":_vm.sonData,"footContent":_vm.footContent}}),_c('Snackbar'),_c('ConfirmBox',{ref:"confirm"})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }